import PremiumPlans from "./PremiumPlans";
import "./styles.css";
import UserReviews from "./UserReviews";

const PremiumScreen = () => {
  return (
    <div className="PremiumScreenContainer">
      <img
        src={require("../../assets/payment_screen/payment_screen_cover.webp")}
        className="PremiumCoverImage"
      />
      <PremiumPlans />
      <img
        src={require("../../assets/payment_screen/premium_benefits.webp")}
        className="PremiumBenefits"
      />
      <UserReviews />
      <PremiumPlans />
    </div>
  );
};

export default PremiumScreen;
