import { useState } from "react";

import { hRatio } from "../../constants/utils";
import TextInput from "../../components/TextInput";
import ActionButton from "../../components/ActionButton";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import UserStore from "../../stores/UserStore";

const SignUp = () => {
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: null, password: null });
  const isFormValid = formValues.password && !errors.password;
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value)) {
      setErrors({ email: "Enter valid email address" });
    } else {
      setErrors({ email: "" });
    }
    setFormValues((prev) => ({ ...prev, email: event.target.value }));
  };

  const handlePasswordChange = (event) => {
    setFormValues((prev) => ({ ...prev, password: event.target.value }));
    UserStore.setFields("password", event.target.value);
  };

  const handleNavigation = () => {
    navigate("/download");
    UserStore.updateUserEmail();
  };

  return (
    <div className="SignUpBG">
      <div style={{ height: 483 * hRatio }} />
      <p className="SignUpTitle">You are all set!</p>
      <p className="SignUpSubtitle">Complete your account to get started</p>
      <img
        src={require("../../assets/sign_up/sign_up.webp")}
        style={{
          height: "100%",
          width: "100%",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
        }}
      />
      <TextInput
        id="email"
        title={"your email *"}
        placeholder={UserStore.email}
        style={{ marginTop: 134 * hRatio }}
        onChange={() => {}}
        error={""}
        type={"email"}
        isEditable={false}
        inlineCustomStyle={` 
                    #email::placeholder { 
                        color: rgba(0, 0, 0, 0.63); 
                    }`}
      />
      <TextInput
        title={"password *"}
        placeholder={"enter password"}
        style={{ marginTop: 102 * hRatio }}
        onChange={handlePasswordChange}
        error={errors.password}
        type={"password"}
      />
      <ActionButton
        text={"Get started"}
        disabled={!isFormValid}
        onClick={handleNavigation}
      />
    </div>
  );
};

export default SignUp;
