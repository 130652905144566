import { Observer } from "mobx-react";

import QuestionareStore from "../../stores/QuestionareStore";

const Footer = () => {
  return (
    <Observer>
      {() => {
        const isNextDisabled = QuestionareStore.isNextButtonDisabled();
        const isBackDisabled = QuestionareStore.currentQuestionIndex == 0;

        return (
          <div>
            <input
              type="button"
              value="back"
              className="BackBtn"
              style={{ opacity: isBackDisabled ? 0.5 : 1 }}
              disabled={isBackDisabled}
              onClick={QuestionareStore.handleBackPress}
            />
            <input
              type="button"
              value="next"
              className="NextBtn"
              style={{ opacity: isNextDisabled ? 0.5 : 1 }}
              disabled={isNextDisabled}
              onClick={QuestionareStore.handleNextPress}
            />
          </div>
        );
      }}
    </Observer>
  );
};

export default Footer;
