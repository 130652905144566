import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import { Observer } from "mobx-react";

import { hRatio, wRatio } from "../../../constants/utils";
import CheckCircle from "../../../icons/CheckCircle";
import CheckCircleFilled from "../../../icons/CheckCircleFilled";
import LockIcon from "../../../icons/LockIcon";
import PremiumStore from "../../../stores/PremiumStore";
import "./styles.css";

const PremiumPlans = () => {
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();

  const PremiumPlanCard = ({ style, isSelected, offer, data }) => {
    return (
      <div
        className="PremiumCard"
        style={{
          ...style,
          backgroundColor: isSelected ? "#6ABAF2" : "rgba(0, 0, 0, 0.08)",
          height: offer ? 439 * hRatio : 295 * hRatio,
        }}
        onClick={() => PremiumStore.handlePlanSelection(data)}
      >
        {offer && (
          <div className="Save50">
            <p className="Save50Text">{offer}</p>
          </div>
        )}
        <div className="FlexSpaceBetween">
          <div>
            <div className="FlexRow" style={{ alignItems: "center" }}>
              {isSelected ? <CheckCircleFilled /> : <CheckCircle />}
              <p className="PremiumDuration">{data.duration}</p>
            </div>
            <div className="FlexRow">
              <p className="PremiumPrice">{`${data.currencySymbol}${data.price}`}</p>
              {data.strikeThroughPrice && (
                <p className="PremiumCutPrice">{`${data.currencySymbol}${data.strikeThroughPrice}`}</p>
              )}
            </div>
          </div>
          <div className="DayPrice">
            <div className="FlexRow">
              <p className="PerDayPriceSymbol">{data.currencySymbol}</p>
              <p className="PerDayPrice">{data.perDayPrice}</p>
            </div>
            <p className="PerDayText">a day</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Observer>
      {() => (
        <div className="PremiumPlansContainer">
          <p className="PremiumPlansTitle">Choose your plan</p>
          <div className="PremiumPlans">
            {PremiumStore.PREMIUM_PLANS.map((item, index) => {
              return (
                <PremiumPlanCard
                  key={item.id}
                  style={index > 0 ? { marginTop: 63 * hRatio } : {}}
                  isSelected={item.id === PremiumStore.selectedPremiumPlan.id}
                  offer={item.offer}
                  data={item}
                />
              );
            })}
          </div>
          <div
            className="SubscribeBtn"
            onClick={() =>
              PremiumStore.handlePaymentCheckout(Razorpay, navigate)
            }
          >
            <LockIcon />
            <p className="SubscribeBtnTxt">Start your free trial</p>
          </div>
        </div>
      )}
    </Observer>
  );
};

export default PremiumPlans;
