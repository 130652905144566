const TestimonialCard = ({ description, author }) => {
  return (
    <div className="TestimonialCard">
      <div className="Ratings">
        <img
          src={require("../../assets/metrics/rating_stars.webp")}
          className="RatingIcon"
        />
      </div>
      <p className="Testimonial">“{description}”</p>
      <p className="UserName">{author}.</p>
    </div>
  );
};

export default TestimonialCard;
