import { useEffect } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import Questionare from "./screens/Questionare";
import { setDimensionsRatio } from "./constants/utils";
import Metrics from "./screens/Metrics";
import WelcomeScreen from "./screens/WelcomeScreen";
import Loading from "./screens/Loading";
import SignUp from "./screens/SignUp";
import DownloadApp from "./screens/DownloadApp";
import PremiumScreen from "./screens/PremiumScreen";
import ADHDApp from "./adhd_web_flow/App";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <WelcomeScreen />,
    },
    {
      path: "/metrics",
      element: <Metrics />,
    },
    {
      path: "/questionare",
      element: <Questionare />,
    },
    {
      path: "/loading",
      element: <Loading />,
    },
    {
      path: "/premium",
      element: <PremiumScreen />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/download",
      element: <DownloadApp />,
    },
    {
      path: "/adhd/*",
      element: <ADHDApp />
    }
  ]);

  useEffect(() => {
    setDimensionsRatio();
  }, []);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
