import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Observer } from "mobx-react";

import LinearProgress from "../../components/LinearProgress";
import Footer from "./Footer";
import MCQOption from "./MCQOption";
import { hRatio } from "../../constants/utils";
import QuestionareStore from "../../stores/QuestionareStore";
import "./styles.css";
import EmailPrompt from "./EmailPrompt";

const Questionare = () => {
  const navigate = useNavigate();

  useEffect(() => {
    QuestionareStore.setNavigation(navigate);
  }, []);

  const QuestionComponent = ({ item }) => {
    return (
      <Observer>
        {() => {
          return (
            <div>
              <p className="Question">{item.question}</p>
              {item.isMultiSelect ? (
                <p className="Subtitle">select all that apply!</p>
              ) : (
                <br />
              )}
              <div style={{ marginTop: 113 * hRatio }}>
                {item.options.map((item, index) => (
                  <MCQOption
                    isSelected={item.isSelected}
                    key={index}
                    text={item.text}
                    onClick={() => QuestionareStore.selectOption(index)}
                  />
                ))}
              </div>
            </div>
          );
        }}
      </Observer>
    );
  };

  return (
    <Observer>
      {() => {
        const currentQuestionIndex = QuestionareStore.currentQuestionIndex;
        const progress =
          (currentQuestionIndex / (QuestionareStore.QUESTIONS.length + 1)) *
          100;
        return (
          <div className="Background">
            <img
              src={require("../../assets/questionare/questionare_background.webp")}
              style={{ height: "100%", width: "100%", position: "absolute" }}
            />
            <div className="Container">
              <div className="BarContainer">
                <LinearProgress progress={progress} />
              </div>
              {QuestionareStore.currentQuestionIndex === 4 ? (
                <EmailPrompt />
              ) : (
                <QuestionComponent
                  item={QuestionareStore.QUESTIONS[currentQuestionIndex]}
                />
              )}
              <Footer />
            </div>
          </div>
        );
      }}
    </Observer>
  );
};

export default Questionare;
