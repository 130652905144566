import USER_REVIEWS from "../../constants/user_reviews";
import TestimonialCard from "../Metrics/TestimonialCard";

const UserReviews = () => {
  return (
    <div className="ReviewsContainer">
      <p className="ReviewsTitle">People ❤️ Evolve</p>
      <p className="ReviewsSubtitle">
        become part of 1 million+ growing community
      </p>
      <div className="UserReviews">
        <TestimonialCard
          description={USER_REVIEWS[0].description}
          author={USER_REVIEWS[0].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[1].description}
          author={USER_REVIEWS[1].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[2].description}
          author={USER_REVIEWS[2].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[3].description}
          author={USER_REVIEWS[3].author}
        />
      </div>
    </div>
  );
};

export default UserReviews;
