import { useState } from "react";

import TextInput from "../../components/TextInput";
import { hRatio } from "../../constants/utils";
import UserStore from "../../stores/UserStore";

const InputContainerStyle = {
  marginTop: 114 * hRatio,
  backgroundColor: "#ffdb62",
};

const InputStyle = {
  backgroundColor: "#ffdb62",
  borderColor: "black",
};

const InputLabelStyle = {
  backgroundColor: "#ffdb62",
  color: "black",
  height: 48 * hRatio,
};

const EmailPrompt = () => {
  const [email, setEmail] = useState(UserStore.email);
  const [error, setError] = useState(null);

  const handleEmailChange = (event) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value)) {
      setError("Enter valid email address");
      UserStore.setFields("isEmailValid", false);
    } else {
      setError("");
      UserStore.setFields("isEmailValid", true);
    }
    setEmail(event.target.value);
    UserStore.setFields("email", event.target.value);
  };

  return (
    <div style={{ marginTop: 414 * hRatio }}>
      <p className="EmailPrompt">Please share your preferred email address</p>
      <p className="EmailSubPrompt">
        Note that this will be the email ID used to create your Evolve account.
      </p>
      <TextInput
        title={"your email *"}
        placeholder={"enter email"}
        style={InputContainerStyle}
        onChange={handleEmailChange}
        error={error}
        type={"email"}
        inputStyle={InputStyle}
        labelStyle={InputLabelStyle}
        value={email}
      />
    </div>
  );
};

export default EmailPrompt;
