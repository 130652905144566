import { useNavigate } from "react-router-dom";

import ActionButton from "../../components/ActionButton";
import Evolve from "../../icons/Evolve";
import "./styles.css";
import TestimonialCard from "./TestimonialCard";
import USER_REVIEWS from "../../constants/user_reviews";

const Metrics = () => {
  const navigate = useNavigate();

  const handleBtnPress = () => {
    navigate("/loading");
  };
  return (
    <div className="MetricsContainer">
      <div className="EvolveIcon">
        <Evolve />
      </div>
      <p className="MetricsTitle">
        See an 67% increase in your mental wellness scores in four weeks
      </p>
      <img className="Graph" src={require("../../assets/metrics/graph.webp")} />
      <div className="Testimonials">
        <TestimonialCard
          description={USER_REVIEWS[0].description}
          author={USER_REVIEWS[0].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[1].description}
          author={USER_REVIEWS[1].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[2].description}
          author={USER_REVIEWS[2].author}
        />
        <TestimonialCard
          description={USER_REVIEWS[3].description}
          author={USER_REVIEWS[3].author}
        />
      </div>
      <ActionButton
        text={"See my personalized plan"}
        onClick={handleBtnPress}
      />
    </div>
  );
};

export default Metrics;
