import ActionButton from "../../components/ActionButton";
import "./styles.css";
import { getMobileOperatingSystem } from "../../constants/utils";

const DownloadApp = () => {
  const handleBtnPress = () => {
    if (getMobileOperatingSystem() === "iOS") {
      window.location.href =
        "https://apps.apple.com/in/app/evolve-selfcare-for-beginners/id1515433542";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=in.evolve.android&hl=en_IN";
    }
  };

  return (
    <div className="DownloadAppBG">
      <img
        src={require("../../assets/download_app_screen/dowload_app_bg.webp")}
        style={{
          height: "100%",
          width: "100%",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
        }}
      />
      <ActionButton text={"Download Evolve"} onClick={handleBtnPress} />
    </div>
  );
};

export default DownloadApp;
