import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";

import "./styles.css";
import { hRatio, wRatio } from "../../constants/utils";
import LoadingAnimation from "../../assets/loading/loading_animation.json";

const Loading = () => {
  const navigate = useNavigate();
  return (
    <div className="LoadingContainer">
      <img
        src={require("../../assets/loading/loading_bg.webp")}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: -1,
        }}
      />
      <div style={{ height: 564 * hRatio }} />
      <Lottie
        animationData={LoadingAnimation}
        loop={false}
        onComplete={() => navigate("/premium")}
        style={{
          height: 511 * hRatio,
          width: 511 * wRatio,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <p className="LoadingTitle">You are all set!</p>
      <p className="LoadingSubtitle">Evolve is being personalized for you</p>
    </div>
  );
};

export default Loading;
