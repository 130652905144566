import { useNavigate } from "react-router-dom";

import ActionButton from "../../components/ActionButton";
import "./styles.css";
import { hRatio } from "../../constants/utils";

const WelcomeScreen = () => {
  const navigate = useNavigate();

  const handleBtnPress = () => {
    navigate("/questionare");
  };

  return (
    <div className="WelcomeScreenBG">
      <img
        src={require("../../assets/welcome_screen/welcome_screen.webp")}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      />
      <ActionButton
        text={"Start your journey, it’s free"}
        onClick={handleBtnPress}
        containerStyle={{ bottom: 185 * hRatio }}
      />
      <div className="FooterContainer">
        <p className="TncText">
          <a
            href="https://evolveinc.io/privacy/"
            style={{ color: "rgba(0, 0, 0, 0.55)" }}
          >
            Privacy Policy |{" "}
          </a>
          <a
            href="https://evolveinc.io/terms/"
            style={{ color: "rgba(0, 0, 0, 0.55)" }}
          >
            Terms and Conditions |{" "}
          </a>
          <a
            href="https://evolveinc.io/return-refund/"
            style={{ color: "rgba(0, 0, 0, 0.55)" }}
          >
            Refund Policy |{" "}
          </a>
          <a
            href="https://evolveinc.io/shop/"
            style={{ color: "rgba(0, 0, 0, 0.55)" }}
          >
            Deliverable Policy |{" "}
          </a>
          <a
            href="https://evolveinc.io/get-in-touch/"
            style={{ color: "rgba(0, 0, 0, 0.55)" }}
          >
            Contact Us
          </a>
        </p>
        <p className="CompanyName">
          GTA Solutions Private Limited, all rights reserved
        </p>
      </div>
    </div>
  );
};

export default WelcomeScreen;
