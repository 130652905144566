import { useState } from "react";

import CrossEye from "../../icons/CrossEye";
import "./styles.css";

const TextInput = ({
  id=""
,  title,
  placeholder,
  value,
  onChange,
  style,
  error,
  type,
  inputStyle,
  labelStyle,
  isEditable = true,
  inlineCustomStyle = `{}`,
}) => {
  const [inputType, setInputType] = useState(type);
  const isPasswordInput = inputType === "password";

  return (
    <div className="InputContainer" style={style}>
      <div className="InputTitleContainer">
        <p className="InputTitle" style={labelStyle}>
          {title}
        </p>
      </div>
      <input
        id={id}
        className="TextInput"
        placeholder={placeholder}
        type={inputType}
        onChange={onChange}
        autoComplete="current-password"
        style={inputStyle}
        value={value}
        disabled={!isEditable}
      />
      <style>{inlineCustomStyle}</style>
      {isPasswordInput && (
        <div onClick={() => setInputType("text")} className="ShowPassword">
          {" "}
          <CrossEye />{" "}
        </div>
      )}
      {error && <p className="InputError">{error}</p>}
    </div>
  );
};

export default TextInput;
