import Tick from "../../icons/Tick";

const MCQOption = ({ isSelected, text, onClick }) => {
  return (
    <div
      className="MCQOption"
      style={{
        backgroundColor: isSelected
          ? "rgba(0, 0, 0, 0.55)"
          : "rgba(0, 0, 0, 0.21)",
      }}
      onClick={onClick}
    >
      <p className="MCQText">{text}</p>
      <div>{isSelected && <Tick />}</div>
    </div>
  );
};

export default MCQOption;
