const height = window.innerHeight;
const width = Math.min(window.innerWidth, 0.462 * height);

export const hRatio = height / 2688;
export const wRatio = width / 1242;

export const setDimensionsRatio = () => {
  document.documentElement.style.setProperty("--hRatio", hRatio);
  document.documentElement.style.setProperty("--wRatio", wRatio);
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
};
